var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mt-5"
  }, [_vm.getInfoErrorMessage ? _c('div', [_c('no-data', {
    attrs: {
      "full-page-error": "",
      "first-text": "Ops!",
      "second-text": _vm.getInfoErrorMessage,
      "withButton": ""
    }
  })], 1) : _c('div', [_c('router-view')], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }