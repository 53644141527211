<template>
  <div class="mt-5">
    <div v-if="getInfoErrorMessage">
      <no-data
        full-page-error
        first-text="Ops!"
        :second-text="getInfoErrorMessage"
        withButton
      >
      </no-data>
    </div>
    <div v-else>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { INFO } from "@/store/apps/app.module";
import { mapGetters, mapMutations } from "vuex";
import NoData from "./Common/NoData.vue";

export default {
  components: { NoData },
  computed: {
    ...mapGetters({
      appActive: "getAppActivity",
      getInfoErrorMessage: "getInfoErrorMessage",
    }),
  },
  created() {
    this.toggleAppActivity(true);
    if (this.$vuetify.theme.dark) {
      this.$vuetify.theme.dark = true;
    }
    this.$store
      .dispatch(INFO, this.$route.params.app_id)
      .then(({ data }) => {
        this.setDefaultApp(data);
      })
      .catch((err) => {
        this.setInfoErrorMessage(err?.message);
      });
  },
  beforeDestroy() {
    this.toggleAppActivity(false);
    this.clearAppInfo();
  },
  methods: {
    ...mapMutations({
      toggleAppActivity: "toggleAppActivity",
      clearAppInfo: "clearAppInfo",
      setDefaultApp: "setDefaultApp",
      setInfoErrorMessage: "setInfoErrorMessage",
    }),
  },
};
</script>

<style lang="scss" scoped></style>
